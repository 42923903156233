import React from "react"
import logo from "../../images/X5-Black.svg";
import styled from "styled-components"
import { Link } from "gatsby"

const Logo = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
width: 300px;
`

const warranty = () => {
  return (
    <>
      <div className="container mx-auto">
      <Logo>
        <img src={logo} alt="X-Five Logo" />
      </Logo>
      <div><h1 className="py-5 text-2xl font-bold">Warranty Policy</h1></div>

      <div className="py-5">
        <h2 className="font-bold">Warranty Portal</h2>
        <Link className="underline text-blue-400" to="/warrantyClaim">Warranty Claim Form</Link>
      </div>

      <div className="py-5">
        <h2 className="font-bold">Limited Warranty on XFive Wearables</h2>
        <p>This limited warranty gives you, the consumer, specific legal rights. You may also be entitled to certain rights, which vary from state to state. The limited warranty can also be found in the documentation provided with the product. We warrant that during the warranty period, this product will be free from defects in materials and workmanship. We limit the duration and remedies of all implied warranties including, without limitation, the warranties of merchantability and fitness for a particular purpose for the duration of this express limited warranty. Some states do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you. Our responsibility for defective goods is limited to repair,replacement, or refund as described below in this warranty statement.</p>
      </div>

      <div className="py-5">
        <p><b>What is the period of coverage?</b><span style={{ fontWeight: 400 }}> This limited warranty starts on the date of your purchase and lasts for one year (the "warranty period"). The warranty period is not extended if we repair or replace the product. We may change the availability of this limited warranty at our discretion,but any changes will not be retroactive.</span></p>
      </div>

      <div className="py-5">
        <p><b>Who may use this warranty?</b><span style={{ fontWeight: 400 }}> XFive Wearables ("we") extend this limited warranty only to the consumer who originally purchased the product ("you"). It does not extend to any subsequent owner or other transferee of the product.</span></p>
      </div>

      <div className="py-5">
        <p><b>What does this warranty cover?</b><span style={{ fontWeight: 400 }}> This limited warranty covers defects in materials and workmanship of all itouch wearables products (the "product")for the warranty period as defined below.</span></p>
      </div>

      <div className="py-5">
        <p><b>What does this warranty not cover?</b><span style={{ fontWeight: 400 }}> This limited warranty does not cover any damage due to: (a) transportation; (b) storage; (c) improper use; (d) failure to follow the product instructions or to perform any preventive maintenance; (e) modifications; (f) unauthorized repair; (g) normal wear and tear; or (h) external causes such as accidents,abuse, water damage or other actions or events beyond our reasonable control.</span></p>
      </div>

      <div className="py-5">
        <p><b>Devices that are not IP68 should not be submerged or used in water under any circumstances as this could lead to irreparable damage</b><span style={{ fontWeight: 400 }}>.</span></p>
      </div>

      <div className="py-5">
        <p><b>What are your remedies under this warranty?</b><span style={{ fontWeight: 400 }}> With respect to any defective product during the warranty period, we will, in our sole discretion, either: (a) repair or replace such product (or the defective part) free of charge or (b) replace the product with same or similar product or (c)refund the purchase price of such product.</span></p>
      </div>

      <div className="py-5">
        <p><b>How do you obtain warranty service? </b>Should you have any issues with your device please first reach out to our customer care team who will be happy to assist you with any issues you may be having. The customer care team will also provide you with a ticket number should a warranty claim need to be made. <span style={{ fontWeight: 400 }}>To obtain warranty service, you must submit your claim through our warranty portal </span><span style={{ fontWeight: 400 }}>(<a href="https://itouchwearables.com/pages/warranty-claim-form" target="_blank" rel="noopener noreferrer">https://itouchwearables.com/pages/warranty-claim-form</a></span><span style={{ fontWeight: 400 }}>)</span><span style={{ fontWeight: 400 }}> during the warranty period. Shortly after submitting a claim, you will receive a warranty confirmation email, which you will need to include with your return package product when returning it to us. No warranty service will be provided without the confirmation email included in the package.</span></p>
      </div>

      <div className="py-5">
        <p><b>Limitation of Liability: </b><span style={{ fontWeight: 400 }}>The remedies described above are your sole and exclusive remedies and our entire liability for any breach of this limited warranty. Our liability shall under no circumstances exceed the actual amount paid by you for the defective product, nor shall we under any circumstances be liable for any consequential, incidental, special or punitive damages or losses, whether direct or indirect. Some states do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</span></p>
      </div>

      <div className="py-5">
        <p><b>What can you do in case of a dispute? </b><span style={{ fontWeight: 400 }}>The following informal dispute resolution procedure is available to you if you believe that we have not performed our obligations under this limited warranty: you can open a support ticket at </span><b>support@xfivewearables.com</b><span style={{ fontWeight: 400 }}>. From there, our customer experience team can assist with any warranty issues.</span></p>
      </div>

      <div className="py-5">
        <p><b>US Warranty Only</b> <span style={{ fontWeight: 400 }}>The XFive Wearables only offers warranty services within the domestic U.S. If you are making a warranty claim, you will be obligated to send your device to our warranty/return center at:</span></p>
      </div>

      <div className="py-5">
        <p><b>Warranty Claims - Please only send your devices here once directed to by our Customer Experience team</b></p>
        <p><span style={{ fontWeight: 400 }}>ATTN: Service Department</span><br /><span style={{ fontWeight: 400 }}>7350 San Gorgonio Drive</span><br /><span style={{ fontWeight: 400 }}>Riverside, CA 92508</span></p>
      </div>

      <div className="py-5">
        <p>Our warranty team will then process your return. Please note that we only ship back out to domestic addresses. We do not ship to any P.O. boxes, Puerto Rico, Alaska, or Hawaii.</p>
        <p><span style={{ fontWeight: 400 }}>The battery for your device cannot be replaced under any circumstances. If you experience any issues with your watch, please contact our customer service center.</span></p>
      </div>

      <div className="py-5">
        <p><span style={{ fontWeight: 400, color: "#808080" }}>NOTE: This equipment has been tested and found to comply with the limits for a Class B digital device, pursuant to part 15 of the FCC Rules.These limits are designed to provide reasonable protection against harmful interference in a residential installation. This equipment generates uses and can radiate radio frequency energy and, if not installed and used in accordance with the instructions, may cause harmful interference to radio communications. However, there is no guarantee that interference will not occur in a particular installation. If this equipment does cause harmful interference to radio or television reception, which can be determined by turning the equipment off and on, the user is encouraged to try to correct the interference by one or more of the following measures: Reorient or relocate the receiving antenna. Increase the separation between the equipment and receiver. Connect the equipment into an outlet on a circuit different from that to which the receiver is connected. Consult the dealer or an experienced radio/TV technician for help changes or modifications not expressly approved by the party responsible for compliance could void the user’s authority to operate the equipment.</span></p>
      </div>
      <div className="py-5">
        <p><span style={{ fontWeight: 400, color: "#808080" }}>This device complies with Part 15 of the FCC Rules.Operation is subject to the following two conditions:</span></p>
        <ul style={{listStylePosition: "outside"}}>
          <li><span style={{ fontWeight: 400, color: "#808080" }}>1. this device may not cause harmful interference, and</span></li>
          <li><span style={{ fontWeight: 400, color: "#808080" }}>2. this device must accept any interference received, including interference that may cause undesired operation.</span></li>
        </ul>
      </div>
      <div className="py-5">
        <p><span style={{ fontWeight: 400, color: "#808080" }}>This equipment complies with FCC RF radiation exposure limits set forth for an uncontrolled environment. This device and its antenna must not be located or operated in conjunction with any other antenna or transmitter.</span></p>
        </div>
        <div className="py-5">
          <p><span className="italic" style={{ fontWeight: 400, color: "#808080" }}>*If you would like to receive a printed version of this policy please email support@xfivewearables.com to request one is sent out to you.</span></p>
        </div>

      </div>
    </>
  )
}

export default warranty
